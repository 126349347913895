import React from 'react';
import { graphql } from 'gatsby';
import {
  DontDo,
  IconItemList,
  List,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import pageHeroData from '../../../../data/pages/components.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Sports"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Icons" tierThree="Sports" />
      <V5Notice
        componentName="Icon"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/general-icon--docs"
      />
      <PageNavigation links={['Icons', 'Usage', 'Platform']} />
      <Section title="Icons">
        <IconItemList data={data} />
      </Section>
      <Section title="Usage">
        <Paragraph>
          It goes without saying that sports are a big part of what we do. It's
          only right that we represent them all.
        </Paragraph>
        <DontDo
          dontText="combining sport icons with Hudl product logos."
          doText="represent a type of sport when it needs to be distinguished."
          imgFilename="icons-sports"
        />
      </Section>
      <Section title="Platform">
        <PlatformTable
          platforms={{
            web: (
              <List>
                <li>Stick to the icons above.</li>
              </List>
            ),
            apple: (
              <List>
                <li>Stick to the icons above.</li>
              </List>
            ),
            android: (
              <List>
                <li>Stick to the icons above.</li>
              </List>
            ),
          }}
        />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query iconSportsDesign($title: String = "Sports") {
    ...iconDataDesign
  }
`;
